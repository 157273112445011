/* eslint-disable */
export default [
  {
    header: " ",
  },
  {
    title: "Módulo Restricciones",
    icon: "StopCircleIcon",
    children: [
      {
        title: "Restricciones",
        children: [
          {
            id: "provisionales",
            title: "Provisionales",
            route: "provisionales",
          },
          {
            id: "restricciones-liberar",
            title: "Restricciones x Liberar",
            route: "restricciones-liberar",
          },
          {
            id: "restricciones-liberar-2",
            title: "Restricciones x Liberar Asignadas",
            route: "restricciones-liberar-2",
          },
          {
            id: "restricciones",
            title: "Lista de Restricciones",
            route: "restricciones",
          },
        ],
      },
      {
        title: "Alertas",
        children: [
          {
            icon: "CircleIcon",
            id: "consultas",
            title: "Consultas",
            route: "consultas",
          },     
          {
            icon: "CircleIcon",
            id: "scheduler",
            title: "Tareas Programadas",
            route: "scheduler",
          },
          {
            icon: "CircleIcon",
            id: "alertas",
            title: "Lista de Alertas",
            route: "alertas",
          },        
          {
            icon: "CircleIcon",
            id: "alertas-cowner",
            title: "Lista de Alertas Co Dueño",
            route: "alertas-cowner",
          },          
        ],
      },
      {
        title: "Reportes",
        children: [
          {
            icon: "CircleIcon",
            id: "power-bi",
            /* title: "Dashboard", */
            title: "Tabla",
            route: "reportes",
           
          },
          {
            icon: "CircleIcon",
            id: "reportes",
            title: "Dashboard",
            /* title: "Tabla", */
            route: "power-bi",
          },
        ],
      },
    ],
  },
  {
    title: "Módulo Corporativo",
    icon: "StopCircleIcon",
    children: [
      {
        title: "Corporativo",
        children: [
          {
            icon: "CircleIcon",
            id: "mantenedor-proyectos",
            title: "Proyecto",
            route: "proyectos",
          },
          {
            icon: "CircleIcon",
            id: "template-areas",
            title: "Area",
            route: "template-areas",
          },
          {
            icon: "CircleIcon",
            id: "template-categoria",
            title: "Categoria",
            route: "template-categoria",
          },
          {
            icon: "CircleIcon",
            id: "template-sub-categoria",
            title: "SubCategoria",
            route:  "template-sub-categoria",
          },
          {
            icon: "CircleIcon",
            id:"template-especialidades",
            title: "Especialidades",
            route: "template-especialidades",
          },
          {
            icon: "CircleIcon",
            id: "mantenedor-usuarios-corporativo",
            title: "Usuarios OP",
            route: "usuarios-corporativo",
          },
        ],
      },    
      {
        title: "Reportes",
        children: [                
          {
            icon: "CircleIcon",
            id: "power-bi",
            /* title: "Dashboard", */
            title: "Tabla",
            route: "reportes",
          },
          {
            icon: "CircleIcon",
            id: "graph-projects",
            title: "Dashboard",
            route: "graph-projects",
          },
        ],
      },    
      {
        title: "Alertas",
        children: [                
          {
            icon: "CircleIcon",
            id: "consultas-cowner",
            title: "Consultas Corporativas",
            route: "consultas-cowner",
          },
          {
            icon: "CircleIcon",
            id: "scheduler-cowner",
            title: "Tareas Programadas Corporativas",
            route: "scheduler-cowner",
          },    
          {
            icon: "CircleIcon",
            id: "alertas-cowner",
            title: "Lista de Alertas Corporativas",
            route: "alertas-cowner",
          }, 
        ],
      },    
    ],
  },
  {
    title: "RFI",
    icon: "StopCircleIcon",
    children: [  
      {
        title: "RFI",      
        children: [
          {
            icon: "CircleIcon",
            id: "rfi",
            title: "RFI",
            route: "rfi",
          },
        ],
      },
    ],
  },
  //LECCIONES
  {
    title: "Lecciones",
    icon: "FolderIcon",
    children: [
      {
        icon: "CircleIcon",
        id: "lecciones",
        title: "Lecciones Aprendidas",
        route: "lecciones",
      },
      {
        icon: "CircleIcon",
        id: "lecciones-inbox",
        title: "Bandeja Lecciones Aprendidas",
        route: "lecciones-inbox",
      },
    ],
  },
  {
    icon: "FolderIcon",
    id: "lecciones-registro",
    title: "Lecciones Agregar",
    route: "lecciones-registro",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "lecciones-editar",
    title: "Lecciones Editar",
    route: "lecciones-editar",
    hide: true,
  },
  //RESTRICCIONES
  {
    icon: "FolderIcon",
    id: "restricciones-registro",
    title: "Restricciones Agregar",
    route: "restricciones-registro",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "restricciones-editar",
    title: "Restricciones Editar",
    route: "restricciones-editar",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "restricciones-vista",
    title: "Restricciones Vista",
    route: "restricciones-vista",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "restricciones-importar-datos",
    title: "Restricciones Importar",
    route: "restricciones-importar-datos",
    hide: true,
  },
  //PROVISIONAL
  {
    icon: "FolderIcon",
    id: "provisionales-registro",
    title: "Provisional Agregar",
    route: "provisionales-registro",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "provisionales-editar",
    title: "Provisional Editar",
    route: "provisionales-editar",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "provisionales-completar",
    title: "provisionales Completar",
    route: "provisionales-completar",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "provisionales-importar-datos",
    title: "Provisional Importar",
    route: "provisionales-importar-datos",
    hide: true,
  },

  //RFI
  {
    icon: "FolderIcon",
    id: "rfi-registro",
    title: "RFI Agregar",
    route: "rfi-registro",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "rfi-editar",
    title: "RFI Editar",
    route: "rfi-editar",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "rfi-importar-datos",
    title: "RFI Importar",
    route: "rfi-importar-datos",
    hide: true,
  },

  //ACTIVIDAD
  {
    icon: "FolderIcon",
    id: "mantenedores-actividad-importar-datos",
    title: "Actividades Importar",
    route: "mantenedores-actividad-importar-datos",
    hide: true,
  },

  {
    icon: "FolderIcon",
    id: "lecciones-registro",
    title: "Lección Agregar",
    route: "lecciones-registro",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "lecciones-editar",
    title: "Lección Editar",
    route: "lecciones-editar",
    hide: true,
  },
  {
    icon: "FolderIcon",
    id: "lecciones-vista",
    title: "Lección Vista",
    route: "lecciones-vista",
    hide: true,
  },
  //seleccionar
  {
    icon: "FolderIcon",
    id: "seleccionar-proyecto",
    title: "Seleccionar Proyecto",
    route: "seleccionar-proyecto",
    hide: true,
  },
  //postLogueo
  {
    icon: "FolderIcon",
    id: "seleccionar-log-type",
    title: "Seleccionar Tipo de Logueo",
    route: "seleccionar-log-type",
    hide: true,
  },
];
